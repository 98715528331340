<template>
  <div class="hello">
    <QuizList :quizType='0'></QuizList>
  </div>
</template>

<script>
import QuizList from '@/components/cubbily-quiz/QuizList';

export default {
  name: 'Offline',
  components: {
    QuizList
  },
  props: {

  },
  data () {
    return {

    };
  },

  methods: {
  },

  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeDestroy () {},
  destroyed () {}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
