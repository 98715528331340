<template>
  <el-form ref="form" :rules="rules" :model="form" label-width="80px">
    <el-form-item label="题目ID">
      <el-input
        v-model="form.quiz_id"
        placeholder="自动生成"
        :disabled="true"
        style="width: 120px;"
      ></el-input>
    </el-form-item>
    <el-form-item label="题目序号" prop="quiz_num">
      <el-input v-model="form.quiz_num" style="width: 120px;"></el-input>
    </el-form-item>
    <el-form-item label="题目内容" prop="content">
      <el-input type="textarea" v-model="form.content" maxlength="150" show-word-limit :rows='4'></el-input>
    </el-form-item>
    <el-form-item label="是否上线">
      <el-select v-model="selectValue" placeholder="请选择">
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item>
      <el-button type="primary" @click="onSubmit('form')">{{addInfo ? '编辑':'新增'}}</el-button>
    </el-form-item>
  </el-form>
</template>

<script>

import { addQuiz, modifyQuiz } from '@/api/cubbily-quiz.js';

export default {
  name: 'AddQuiz',
  props: {
    addInfo: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      form: {
        quiz_id: '',
        quiz_num: '',
        content: ''
      },
      rules: {
        quiz_num: [
          { required: true, message: '请输入题目序号', trigger: 'change' }
        ],
        content: [
          { required: true, message: '请输入题目内容', trigger: 'change' }
        ]
      },
      options: [
        {
          value: 1,
          label: '上线'
        },
        {
          value: 0,
          label: '下线'
        }
      ],
      selectValue: 1
    };
  },

  methods: {
    onSubmit (form) {
      console.log('submit!', this.form, this.selectValue);
      const that = this;
      this.$refs[form].validate((valid) => {
        if (valid) {
          const request = that.addInfo ? modifyQuiz : addQuiz;
          const { quiz_id, quiz_num, content } = that.form;
          const shown = that.selectValue;
          const reqData = that.addInfo ? {
            quiz_id,
            quiz_num,
            content,
            shown
          } : {
            quiz_num,
            content,
            shown
          };
          request(reqData)
            .then((res) => {
              console.log('新增编辑成功：', res);
              if (res.result == 1) {
                that.$emit('addsuccess');
              } else {
                that.$message.error(res.error_msg);
              }
            });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    }
  },

  beforeCreate () {},
  created () {
  },
  beforeMount () {},
  mounted () {
    if (this.addInfo) {
      this.form = this.addInfo;
      this.selectValue = this.addInfo.shown;
    }
  },
  beforeDestroy () {},
  destroyed () {}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
