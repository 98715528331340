<template>
  <div class="quiz-list">
    <el-button v-if="quizType == 1" type="primary" @click="addBtnClick">新建</el-button>
    <el-table class="table-wrap" :data="tableData" @row-dblclick="rowClick" max-height="640" stripe style="width: 100%" :default-sort = "{prop: 'quiz_num', order: 'ascending'}">
      <el-table-column prop="quiz_num" label="题号" sortable> </el-table-column>
      <el-table-column prop="quiz_id" label="题目ID"> </el-table-column>
      <el-table-column prop="content" label="题目内容"> </el-table-column>
      <el-table-column prop="show_user" label="曝光人数" sortable> </el-table-column>
      <el-table-column prop="answer_user" label="回答人数" sortable> </el-table-column>
      <el-table-column prop="user_answer_rate" label="用户回答率" sortable> </el-table-column>
      <el-table-column prop="show_group" label="曝光家庭数" sortable> </el-table-column>
      <el-table-column prop="answer_group" label="回答家庭数" sortable> </el-table-column>
      <el-table-column prop="group_answer_rate" label="家庭回答率" sortable> </el-table-column>
    </el-table>
    <el-pagination
      class="pagination"
      @current-change="handleCurrentChange"
      :current-page.sync="currentPage"
      :page-size="20"
      layout="total, prev, pager, next, jumper"
      :total="total">
    </el-pagination>
    <el-dialog title="新增题目" v-if="showAdd" :visible.sync="showAdd" width="30%">
      <AddQuiz :addInfo="addInfo" @addsuccess="addsuccess"></AddQuiz>
    </el-dialog>
  </div>
</template>

<script>
import AddQuiz from './AddQuiz.vue';
import { getQuizList } from '@/api/cubbily-quiz.js';

export default {
  name: 'QuizList',
  components: {
    AddQuiz
  },
  props: {
    quizType: { // 0: 下线题目 1: 默认题目
      type: Number,
      default: 1
    }
  },
  data () {
    return {
      showAdd: false,
      tableData: [],
      addInfo: null,
      total: 0,
      currentPage: 1
    };
  },

  methods: {
    addBtnClick () {
      this.addInfo = null;
      this.showAdd = true;
    },

    addsuccess () {
      this.showAdd = false;
      this.getData();
    },

    getData () {
      const that = this;
      getQuizList({
        shown: that.quizType,
        begin: 20 * (that.currentPage - 1),
        limit: 20,
        order_type: 1,
        order_by: 'quiz_num'
      })
        .then((res) => {
          console.log('获取列表成功-------', res);
          if (res.result == 1) {
            const { quizs, total } = res.data;
            that.tableData = quizs;
            that.total = total;
          } else {
            that.$message.error(res.error_msg);
          }
        })
        .catch((error) => {
          console.log('获取列表失败-------', error);
        });
    },

    rowClick (row) {
      console.log('双击了某一行------', row);
      const { quiz_id, quiz_num, content } = row;
      this.addInfo = {
        quiz_id,
        quiz_num,
        content,
        shown: this.quizType
      };
      this.showAdd = true;
    },

    handleCurrentChange (val) {
      console.log(`当前页: ${val}----${this.currentPage}`);
      this.getData();
    }
  },

  beforeCreate () {},
  created () {
    this.getData();
  },
  beforeMount () {},
  mounted () {},
  beforeDestroy () {},
  destroyed () {}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.quiz-list {
  padding: 10px;
}
.table-wrap{
  margin-top: 10px;
}

.pagination{
  margin-top: 40px;
}
</style>
