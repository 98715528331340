import httpRequest from '@/utils/network/request';

// 获取问题列表
export const getQuizList = function (params) {
  const url = '/console/quiz/list';
  return httpRequest.get(url, params);
};

// 新建问题
export const addQuiz = function (params) {
  const url = '/console/quiz/new';
  return httpRequest.post(url, params);
};

// 修改问题
export const modifyQuiz = function (params) {
  const url = '/console/quiz/modify';
  return httpRequest.post(url, params);
};